import React from 'react';
import { Accordion } from 'react-bootstrap';
import MarkdownPreview from '@uiw/react-markdown-preview'
import { ToolInteraction } from '../Interfaces';

interface ChainOfThoughtToolsProps {
  toolInteractions: ToolInteraction[];
}

const ChainOfThoughtTools: React.FC<ChainOfThoughtToolsProps> = ({ toolInteractions }) => {
  if (!toolInteractions || toolInteractions.length === 0) {
    return null;
  }

  const formatMessage = (message: any) => {
    if (typeof message === 'string') {
      // Parse potential JSON strings that were stringified
      try {
        const parsed = JSON.parse(message);
        return '```json\n' + JSON.stringify(parsed, null, 2) + '\n```';
      } catch {
        // If it's not JSON, return the original string
        return "> " + message;
      }
    }
    // If it's already an object, stringify it with markdown code block
    return '```json\n' + JSON.stringify(message, null, 2) + '\n```';
  };

  const getToolIcon = (toolName: string) => {
    switch (toolName) {
      case 'query_metadata':
        return 'fa-database';
      case 'fetch_context':
        return 'fa-file-alt';
      case 'search_internet':
        return 'fa-search';
      case 'ingest_channel':
        return 'fa-download';
      default:
        return 'fa-tools';
    }
  };

  return (
    <Accordion className='mt-2 mb-3'>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <i className='fas fa-lightbulb text-warning me-2'></i>
          Chain of Thought
        </Accordion.Header>
        <Accordion.Body>
          {toolInteractions.map((interaction, index) => (
            <div key={index} className="mb-3">
              <div className="tool-header mt-1 mb-1" style={{ fontSize: '1rem' }}>
                <strong>
                  <i className={`fas ${getToolIcon(interaction.content.name)} me-2`}></i>
                  {interaction.type === "tool_call" ? "Tool Call" : "Tool Response"}
                </strong> 
                {': '}{interaction.content.name}
              </div>
              <MarkdownPreview 
                source={formatMessage(interaction.content.message)}
                style={{ 
                  backgroundColor: 'inherit', 
                  color: 'inherit',
                  fontSize: '0.9rem'
                }}
                wrapperElement={{
                  "data-color-mode": "light"
                }}
                components={{
                  code: ({ inline, children, ...props }: any) => {
                    if (inline) {
                      return <code {...props}>{children}</code>;
                    }
                    return (
                      <code style={{
                        whiteSpace: 'pre-wrap',
                      }} {...props}>
                        {children}
                      </code>
                    );
                  },
                  h1: ({ children }) => <h1 style={{ fontSize: '1.1rem' }}>{children}</h1>,
                  h2: ({ children }) => <h2 style={{ fontSize: '1rem' }}>{children}</h2>,
                }}
              />
              {interaction.content.is_success !== undefined && (
                <p>
                  Status:{" "}
                  <span className={interaction.content.is_success ? "text-success" : "text-danger"}>
                    {interaction.content.is_success ? "Success" : "Failed"}
                  </span>
                </p>
              )}
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ChainOfThoughtTools;