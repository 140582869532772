import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { Button, Form, Modal, Spinner, Overlay, Popover } from 'react-bootstrap'
import { getWorker, getWorkerRunVersion } from '../../../api/worker'
import { sendFeedback } from '../../../api/workerChain'
import { Worker, WorkerModal } from '../../../components/Worker Modal'
import { WorkerRunVersion } from '../types'
import { FaPlus, FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { formatDistanceToNow, parseISO } from 'date-fns'
import Markdown from 'react-markdown'
import PreviewComponent from '../../../components/PreviewComponent'
import ChainOfThoughtTools from '../../../components/WorkerCotTools'

const WorkerNode = ({ data }: NodeProps) => {
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [workerFormData, setWorkerFormData] = useState<Worker>()
	const [workerRunVersion, setWorkerRunVersion] = useState<WorkerRunVersion>()
	const [feedback, setFeedback] = useState('')
	const [inlineFeedback, setInlineFeedback] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [selectedText, setSelectedText] = useState('')
	const [showPopover, setShowPopover] = useState(false)
	const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
	const popoverRef = useRef(null)
	const isViewMode = data.mode == 'view'
	const isSuccess = isViewMode && data.output !== undefined
	const isPending = isViewMode && data.output === undefined
	const color = isSuccess ? 'green' : isPending ? '#a9a9a9' : '#3498db'
	const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)

	const formatDate = (dateString: string) => {
		const date = parseISO(dateString)
		return 'Last Updated ' + formatDistanceToNow(date, { addSuffix: true })
	}

	const submitFeedback = useCallback(
		async (feedbackToSubmit: string) => {
			setIsSubmitting(true)
			setIsFeedbackLoading(true)

			try {
				if (data.output?.worker_id && data.output?.run_id && data.output?.version_id) {
					const res = await sendFeedback(
						data.worker_chain_id,
						data.worker_chain_run_id,
						data.worker_chain_run_version_id,
						data.output.worker_id,
						data.output.run_id,
						data.output.version_id,
						feedbackToSubmit
					)

					// Find the matching worker in the response
					const matchingWorker = res.data.output.workers.find(
						(worker: any) =>
							worker.worker_id === data.output.worker_id && worker.run_id === data.output.run_id
					)

					if (matchingWorker) {
						// Start polling for new output
						let newOutput = null
						while (!newOutput) {
							const response = await getWorkerRunVersion(
								data.output.worker_id,
								data.output.run_id,
								matchingWorker.version_id
							)
							newOutput = response.data.output
							if (!newOutput) {
								await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second before next poll
							} else {
								setWorkerRunVersion(response.data)
								break
							}
						}

						data.onFeedback && data.onFeedback(res.data.version_id)
						setFeedback('')
						setSelectedText('')
						setShowPopover(false)
					} else {
						throw new Error('Matching worker not found in the response')
					}
				}
			} catch (error) {
				toast.error('Error sending feedback')
				console.error('Error sending feedback:', error)
			} finally {
				setIsSubmitting(false)
				setIsFeedbackLoading(false)
			}
		},
		[data.output, data.onFeedback, sendFeedback, setIsSubmitting, setFeedback, setSelectedText, setShowPopover]
	)

	const handleFeedbackSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			await submitFeedback(feedback)
		},
		[feedback, submitFeedback]
	)

	const fetchWorkerRunVersion = useCallback(async () => {
		const response = await getWorkerRunVersion(data.output.worker_id, data.output.run_id, data.output.version_id)
		setWorkerRunVersion(response.data)
	}, [data.output])

	const handleDoubleClick = async () => {
		if (isViewMode) {
			if (data.output) {
				setIsLoading(true)
				setShowModal(true)
				await fetchWorkerRunVersion()
				setIsLoading(false)
			}
		} else {
			setIsLoading(true)
			setShowModal(true)
			try {
				const response = await getWorker(data.id)
				setWorkerFormData(response.data)
			} catch (error) {
				console.error('Error fetching worker data:', error)
				toast.error('Error fetching worker data')
			}
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (isViewMode && data.output && showModal) {
			fetchWorkerRunVersion()
		}
	}, [isViewMode, data.output, showModal, fetchWorkerRunVersion])

	const handleTextSelection = () => {
		const selection = window.getSelection()
		if (selection) {
			const newSelectedText = selection?.toString()
			if (newSelectedText) {
				setSelectedText(newSelectedText)
				const range = selection.getRangeAt(0)
				const rect = range.getBoundingClientRect()
				const modalElement = document.querySelector('.modal-content')
				const leftMargin = modalElement?.getBoundingClientRect().left || 0
				setPopoverPosition({ top: -rect.top, left: (rect.left + rect.right) / 2 - leftMargin - 16 })
				setInlineFeedback('')
				setShowPopover(true)
				const textarea = document.querySelector('.popover-body>textarea') as HTMLTextAreaElement
				if (textarea) {
					textarea.focus()
				}
			} else {
				setShowPopover(false)
			}
		} else {
			setShowPopover(false)
		}
	}

	const handleMouseDown = (event: React.MouseEvent) => {
		event.preventDefault()
		const selection = window.getSelection()
		const textarea = event.currentTarget.querySelector('textarea')
		// if (textarea) {
		// 	textarea.focus();
		// }
		if (selection) {
			const range = selection.getRangeAt(0)
			selection.removeAllRanges()
			selection.addRange(range)
		}
	}

	const submitInlineFeedback = useCallback(
		async (feedbackToSubmit: string) => {
			if (selectedText) {
				await submitFeedback(
					`"${selectedText}", Feedback for the quoted text (extracted from the workunit): ${feedbackToSubmit}`
				)
			}
		},
		[selectedText, submitFeedback]
	)

	const handleKeyDown = useCallback(
		async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (event.key === 'Enter' && showPopover) {
				event.preventDefault()
				await submitInlineFeedback(inlineFeedback)
			}
		},
		[showPopover, submitInlineFeedback, inlineFeedback]
	)

	return (
		<>
			<div
				onDoubleClick={handleDoubleClick}
				style={{
					background: '#ffffff',
					border: `2px solid ${color}`,
					borderRadius: '15px',
					padding: '40px',
					width: '200px',
					height: 'auto',
					boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
					fontFamily: 'Arial, sans-serif',
					textAlign: 'center',
				}}>
				<Handle
					type='target'
					id='input'
					position={Position.Top}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						background: 'transparent',
						top: '-12px',
						left: '30%',
						width: 30,
						zIndex: 2,
						cursor: 'grab',
					}}>
					<div
						style={{
							border: `2px solid ${color}`,
							borderRadius: 5,
							width: '100%',
							backgroundColor: 'white',
						}}>
						<FaPlus style={{ color, fontSize: '20px' }} />
					</div>
					<span style={{ color }}>Input</span>
				</Handle>
				<Handle
					type='target'
					id='channel'
					position={Position.Top}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						background: 'transparent',
						top: '-12px',
						left: '70%',
						width: 30,
						zIndex: 2,
						cursor: 'grab',
					}}>
					<div
						style={{
							border: `2px solid ${color}`,
							borderRadius: 5,
							width: '100%',
							backgroundColor: 'white',
						}}>
						<FaPlus style={{ color, fontSize: '20px' }} />
					</div>
					<span style={{ color }}>Channel</span>
				</Handle>
				<Handle
					type='target'
					id='worker'
					position={Position.Left}
					style={{ background: color, height: 20, width: 20, left: '-10px', zIndex: 2, cursor: 'grab' }}
				/>
				<div
					style={{
						color,
						fontWeight: 'bold',
						fontSize: '18px',
					}}>
					{data.title}
				</div>
				<Handle
					type='source'
					id='worker'
					position={Position.Right}
					style={{ background: color, height: 20, width: 20, right: '-8px' }}
				/>
				<Handle
					type='source'
					id='output'
					position={Position.Bottom}
					style={{ background: color, height: 20, width: 20, bottom: '-8px', left: '50%' }}
				/>
			</div>

			{isViewMode && (
				<Modal show={showModal} onHide={() => setShowModal(false)} size='xl'>
					<Modal.Header closeButton>{workerRunVersion?.output?.title ?? data.title}</Modal.Header>
					<Modal.Body className='mb-4' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
						{isLoading ? (
							<div className='text-center'>
								<Spinner animation='border' role='status'>
									<span className='visually-hidden'>Loading...</span>
								</Spinner>
							</div>
						) : (
							<div onMouseUp={handleTextSelection} ref={popoverRef}>
								{workerRunVersion?.output?.tool_interactions && (
									<ChainOfThoughtTools toolInteractions={workerRunVersion?.output?.tool_interactions} />
								)}
								{workerRunVersion?.output?.display_type == 'TEXT' ? (
									<Markdown>{workerRunVersion?.output?.content}</Markdown>
								) : workerRunVersion?.output?.display_type == 'IMAGE' ? (
									<img
									src={workerRunVersion?.output?.content}
									alt='Worker Output'
									style={{ maxWidth: '100%', height: 'auto' }}
									/>
								) : workerRunVersion?.output?.display_type == 'REACT_COMPONENT' ? (
									<PreviewComponent code={workerRunVersion?.output?.content} />
								) : (
									<div></div>
								)}
							</div>
						)}
					</Modal.Body>
					{!isLoading && (
						<Modal.Footer className='d-flex flex-column align-items-start'>
							<div className='my-2' style={{ width: '100%' }}>
								<Form onSubmit={handleFeedbackSubmit}>
									<Form.Group controlId='feedback'>
										<Form.Label>Feedback</Form.Label>
										<Form.Control
											as='textarea'
											rows={3}
											value={feedback}
											onChange={(e) => setFeedback(e.target.value)}
											disabled={isSubmitting || isFeedbackLoading}
										/>
									</Form.Group>
									<div className='mt-2'>
										<Button
											variant='primary'
											type='submit'
											disabled={isSubmitting || isFeedbackLoading}>
											{(isSubmitting || isFeedbackLoading) && (
												<Spinner animation='border' size='sm' className='me-2' />
											)}
											Submit Feedback
										</Button>
									</div>
								</Form>
							</div>
							<div>
								<b>
									<i style={{ color: 'rgb(80, 95, 121)' }}>
										{workerRunVersion?.created_at && formatDate(workerRunVersion?.created_at)}
									</i>
								</b>
							</div>
						</Modal.Footer>
					)}
				</Modal>
			)}

			{!isViewMode && (
				<WorkerModal
					showModal={showModal}
					setShowModal={setShowModal}
					workerFormData={workerFormData}
					setWorkerFormData={setWorkerFormData}
					isLoading={isLoading}
				/>
			)}

			<Overlay
				show={showPopover}
				target={popoverRef.current}
				container={popoverRef.current}
				placement='top-start'
				containerPadding={20}
				rootClose
				popperConfig={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [popoverPosition.left, popoverPosition.top],
							},
						},
					],
				}}>
				<Popover id='popover-contained' onMouseDown={handleMouseDown} style={{ maxWidth: '600px' }}>
					<Popover.Body className='d-flex'>
						<Button
							variant='success'
							onClick={() => submitInlineFeedback('I like the quoted text.')}
							disabled={isSubmitting}>
							<FaThumbsUp />
						</Button>
						<Button
							variant='danger'
							onClick={() => submitInlineFeedback("I don't like the quoted text. Rest all is good.")}
							disabled={isSubmitting}
							className='ms-2'>
							<FaThumbsDown />
						</Button>
						<Form.Control
							as='textarea'
							rows={1}
							placeholder='Type feedback and press Enter'
							value={inlineFeedback}
							onChange={(e) => setInlineFeedback(e.target.value)}
							onKeyDown={handleKeyDown}
							disabled={isSubmitting}
							className='ms-2'
							style={{ minWidth: '450px' }}
						/>
					</Popover.Body>
				</Popover>
			</Overlay>
		</>
	)
}

export default WorkerNode
