import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Dropdown, Spinner, Form, Button } from 'react-bootstrap'
import { getWorkerRun, getWorkerRunVersion, getWorkerRunVersions, sendFeedback } from '../../api/worker'
import { toast } from 'react-toastify'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import MarkdownPreview from '@uiw/react-markdown-preview'
import PreviewComponent from '../../components/PreviewComponent'
import { WorkerRunVersion } from '../WorkerChainBuilder/types'
import { DisplayType, WorkerOutput } from '../../components/Interfaces'
import ChainOfThoughtTools from '../../components/WorkerCotTools'


const WorkerRunPreview: React.FC = () => {
	const { workerId, runId, versionId } = useParams<{ workerId: string; runId: string; versionId?: string }>()
	const [workerOutput, setWorkerOutput] = useState<WorkerOutput>()
	const [loading, setLoading] = useState(true)
	const [channelName, setChannelName] = useState('')
	const [time, setTime] = useState('')
	const [feedback, setFeedback] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [versions, setVersions] = useState<WorkerRunVersion[]>([])
	const navigate = useNavigate()
	const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null)

	const fetchWorkerRun = useCallback(async () => {
		try {
			if (workerId && runId) {
				const response = await getWorkerRun(workerId, runId)
				setTime(response.data.updated_at)
				setChannelName(response.data.input.channel_name)
				return response.data
			}
		} catch (error) {
			console.error('Error fetching worker run:', error)
		}
	}, [workerId, runId])

	const fetchWorkerRunVersion = useCallback(
		async (versionIdToFetch: string) => {
			try {
				if (workerId && runId) {
					const response = await getWorkerRunVersion(workerId, runId, versionIdToFetch)
					if (response.data.output && Object.keys(response.data.output).length > 0) {
						setWorkerOutput(response.data.output)
						setLoading(false)
						if (pollingInterval) {
							clearInterval(pollingInterval)
							setPollingInterval(null)
						}
					} else {
						// If output is not available, set up polling
						if (!pollingInterval) {
							const interval = setInterval(() => fetchWorkerRunVersion(versionIdToFetch), 10000)
							setPollingInterval(interval)
						}
					}
				}
			} catch (error) {
				console.error('Error fetching worker run:', error)
			}
		},
		[workerId, runId, pollingInterval]
	)

	const fetchVersions = useCallback(async () => {
		if (workerId && runId) {
			const response = await getWorkerRunVersions(workerId, runId)
			const sortedVersions = response.data.sort(
				(a: WorkerRunVersion, b: WorkerRunVersion) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			)
			setVersions(sortedVersions)
		}
	}, [workerId, runId])

	useEffect(() => {
		fetchVersions()
	}, [fetchVersions])

	const handleVersionSelect = (selectedVersionId: string | null) => {
		if (selectedVersionId) {
			navigate(`/worker/${workerId}/run/${runId}/version/${selectedVersionId}`)
		}
	}

	useEffect(() => {
		setLoading(true)
		fetchWorkerRun().then(async (workerRun) => {
			if (!versionId) {
				const currentVersionId = workerRun.current_version_id
				navigate(`/worker/${workerId}/run/${runId}/version/${currentVersionId}`, { replace: true })
			} else {
				await fetchWorkerRunVersion(versionId)
			}
		})

		// Clean up polling interval on component unmount
		return () => {
			if (pollingInterval) {
				clearInterval(pollingInterval)
			}
		}
	}, [workerId, runId, versionId, fetchWorkerRun, fetchWorkerRunVersion, navigate])

	const formatDateRelative = (dateString: string) => {
		const date = parseISO(dateString)
		return formatDistanceToNow(date, { addSuffix: true })
	}

	const formatDateTime = (dateTimeString: string) => {
		const date = parseISO(dateTimeString)
		return format(date, 'MMMM d, yyyy h:mm a')
	}

	const handleFeedbackSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			setIsSubmitting(true)

			try {
				if (workerId && runId && feedback) {
					const response = await sendFeedback(workerId, runId, feedback, versionId)
					setFeedback('')

					// Start polling for the new version's output
					const newVersionId = response.data.version_id
					let outputAvailable = false

					while (!outputAvailable) {
						const versionResponse = await getWorkerRunVersion(workerId, runId, newVersionId)
						if (versionResponse.data.output && Object.keys(versionResponse.data.output).length > 0) {
							outputAvailable = true
						} else {
							// Wait for 5 seconds before polling again
							await new Promise((resolve) => setTimeout(resolve, 5000))
						}
					}

					// Once output is available, navigate to the new URL
					navigate(`/worker/${workerId}/run/${runId}/version/${newVersionId}`)
					fetchVersions()
				}
			} catch (error) {
				toast.error('Error sending feedback')
				console.error('Error sending feedback:', error)
			} finally {
				setIsSubmitting(false)
			}
		},
		[workerId, runId, versionId, feedback, navigate, fetchVersions]
	)

	if (loading) {
		return (
			<Container fluid className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
				<Spinner animation='border' />
			</Container>
		)
	}

	return (
		<Container fluid>
			<h1>
				{channelName} - {workerOutput?.title}
			</h1>
			<Dropdown onSelect={handleVersionSelect}>
				<Dropdown.Toggle variant='success'>Select Version</Dropdown.Toggle>
				<Dropdown.Menu>
					{versions?.map((version) => (
						<Dropdown.Item key={version.version_id} eventKey={version.version_id}>
							{formatDateTime(version.created_at)}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
			{workerOutput && (
				<div className='my-4'>								
					<ChainOfThoughtTools toolInteractions={workerOutput.tool_interactions} />
					{workerOutput.display_type == DisplayType.TEXT ? (
						<MarkdownPreview
							source={workerOutput.content}
							style={{ backgroundColor: 'inherit', color: 'inherit' }}
							wrapperElement={{
								"data-color-mode": "light"
							}}
						/>
					) : workerOutput.display_type == DisplayType.IMAGE ? (
						<img src={workerOutput.content} />
					) : workerOutput.display_type == DisplayType.VIDEO ? (
						<video controls src={workerOutput.content}>
							Your browser does not support the video tag.
						</video>
					) : workerOutput.display_type == DisplayType.REACT_COMPONENT ? (
						<PreviewComponent code={workerOutput.content} />
					) : (
						<p>Work Unit Type {workerOutput.display_type} not supported yet</p>
					)}
				</div>
			)}
			<div className='my-2'>
				<Form onSubmit={handleFeedbackSubmit}>
					<Form.Group controlId='feedback'>
						<Form.Label>Feedback</Form.Label>
						<Form.Control
							as='textarea'
							rows={3}
							value={feedback}
							onChange={(e) => setFeedback(e.target.value)}
							disabled={isSubmitting}
						/>
					</Form.Group>
					<div className='mt-2'>
						<Button variant='primary' type='submit' disabled={isSubmitting}>
							{isSubmitting && <Spinner animation='border' size='sm' className='me-2' />}
							Submit Feedback
						</Button>
					</div>
				</Form>
			</div>
			<div>
				<b>
					<i style={{ color: 'rgb(80, 95, 121)' }}>{time && 'Last Updated ' + formatDateRelative(time)}</i>
				</b>
			</div>
		</Container>
	)
}

export default WorkerRunPreview
