import React, { useCallback, useState } from 'react'
import { Handle, Node, NodeProps, Position } from 'reactflow'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { uploadFile } from '../../../api/workerChain' // Assuming you have an API function for file upload

const InputNode = ({ id, data }: NodeProps) => {
    const [showModal, setShowModal] = useState(false);
    const [contextContent, setContextContent] = useState(data.content || '');
    const [key, setKey] = useState(data.key || '');
    const [url, setUrl] = useState(data.url || '');
    const [loading, setLoading] = useState(false);

	const handleDoubleClick = async () => {
		setShowModal(true)
	}

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setLoading(true);
            const response = await uploadFile(file);
            const fileKey = response.data;
            setKey(fileKey);
            setLoading(false);
        }
    }

    const handleContextUpdate = useCallback(async () => {
        if (data.id === 'text') {
            data.setNodes((nodes: Node[]) => {
                const updatedNodes = nodes.map(node => {
                    if (node.id === id) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                content: contextContent
                            }
                        };
                    }
                    return node;
                });
                return updatedNodes;
            });
        } else if (data.id === 'file') {
			data.setNodes((nodes: Node[]) => {
                const updatedNodes = nodes.map(node => {
                    if (node.id === id) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                key
                            }
                        };
                    }
                    return node;
                });
                return updatedNodes;
            });
		} else if (data.id === 'url') {
            data.setNodes((nodes: Node[]) => {
                const updatedNodes = nodes.map(node => {
                    if (node.id === id) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                url
                            }
                        };
                    }
                    return node;
                });
                return updatedNodes;
            });
        }
        setShowModal(false);
    }, [data.id, data.setNodes, contextContent, key, url]);

	return (
		<>
			{
				<div
					onDoubleClick={data.mode != 'view' ? handleDoubleClick : undefined}
					style={{
						background: '#ffffff',
						border: `1px solid orange`,
						borderRadius: '15px',
						padding: '20px',
						width: '200px',
						height: 'auto',
						boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
						fontFamily: 'Arial, sans-serif',
						textAlign: 'center',
					}}>
					<Handle
						type='source'
						id='input'
						position={Position.Bottom}
						style={{ background: 'orange', width: 20, height: 20, bottom: -10 }}
					/>
					<div
						style={{
							color: 'orange',
							fontWeight: 'bold',
							fontSize: '18px',
						}}>
						{data.title}
					</div>
				</div>
			}

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Input</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{data.id === 'text' ? (
						<Form.Group className='mb-3'>
							<Form.Control
								as='textarea'
								rows={10}
								value={contextContent}
								onChange={(e) => setContextContent(e.target.value)}
								disabled={loading}
							/>
						</Form.Group>
					) : data.id === 'file' ? (
						<Form.Group className='mb-3'>
							{key ? (
								<div>{key.split('#').pop()}</div>
							) : (
								<>
									<Form.Control
										type='file'
										onChange={handleFileChange}
										disabled={loading}
									/>
									{loading && <Spinner animation="border" />}
								</>
							)}
						</Form.Group>
					) : (
						<Form.Group className='mb-3'>
							<Form.Control
								type='text'
								placeholder='Enter URL'
								value={url}
								onChange={(e) => setUrl(e.target.value)}
								disabled={loading}
							/>
						</Form.Group>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleContextUpdate} disabled={loading}>
						Save Input
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default InputNode
