export const STAGE = process.env.REACT_APP_STAGE ?? 'dev'
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? ''
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL ?? ''
// export const BACKEND_URL = "https://8656sb74x2.execute-api.us-east-1.amazonaws.com/api/"
// export const WEBSOCKET_URL = "wss://8h67dwuwre.execute-api.us-east-1.amazonaws.com/api/"
// export const BACKEND_URL = "https://8000-trilogygrou-youtubebran-j4h3z9r8wh0.ws.trilogy.devspaces.com/"
export const AUTH0_DOMAIN = 'ti-trilogy.us.auth0.com'
export const AUTH0_CLIENT_ID = '7kN1xtbiAD1RcbxvRSqFFxFbf7PQbhft'
export const AUTH0_ROLE_NAMESPACE = 'https://social-surge.ti.trilogy.com/roles'
export const AUTH0_ADMIN_ROLE = 'socialsurge:admin'
export const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY ?? ''
export const POSTHOG_HOST = 'https://us.i.posthog.com'
export const LONG_VIDEO_THRESHOLD = 50
export const VERSION = '5.8.0'
