import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Accordion, Container, Row, Col, Card, Button, Modal, Form, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Loader from '../../components/spinner'
import {
	listAllVideoIdeas,
	updateVideoIdea,
	submitFeedback,
	goToNextStage,
	goToPrevStage,
	rewind,
	deleteVideoIdea,
} from '../../api/videoIdeas'
import Markdown from 'react-markdown'
import logo from './youtube.png'
import './index.css'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { FaTrash } from 'react-icons/fa'

interface DataItem {
	name: string
	type: string
	thought: string
	content: string
}

interface VideoIdea {
	id: string
	channelId: string
	channelDisplayName: string
	stage: string
	data: DataItem[]
	allowRewind: boolean
	allowNextStage: boolean
	allowPrevStage: boolean
	updatedAt: string
	createdAt: string
	stageDisplayName?: string
	nextStageDisplayName?: string
	prevStageDisplayName?: string
	allStages?: string[]
	isProcessing: boolean
	type: string
}

type SortByField = 'createdAt' | 'updatedAt'

const formatRelative = (dateString: string): string => {
	const date = parseISO(dateString)
	return formatDistanceToNow(date, { addSuffix: true })
}

const StagePipeline: React.FC<{ stages: string[]; currentStage?: string }> = ({ stages, currentStage }) => {
	const indexOfCurrentStage = currentStage ? stages.indexOf(currentStage) : -1
	return (
		<div className='stage-pipeline'>
			{stages.map((stage, index) => {
				const isStageCompleted = indexOfCurrentStage == -1 || index < indexOfCurrentStage
				return (
					<div
						key={stage}
						className={`stage ${stage === currentStage ? 'current' : ''} ${
							isStageCompleted ? 'completed' : ''
						}`}>
						{stage}
					</div>
				)
			})}
		</div>
	)
}

const VideoBuilder: React.FC = () => {
	const [videoIdeas, setVideoIdeas] = useState<VideoIdea[]>([])
	const [filteredVideoIdeas, setFilteredVideoIdeas] = useState<VideoIdea[]>([])
	const [selectedVideoIdea, setSelectedVideoIdea] = useState<VideoIdea | null>(null)
	const [showModal, setShowModal] = useState(false)
	const [feedback, setFeedback] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [isRefreshing, setIsRefreshing] = useState(false)
	const [filterStatus, setFilterStatus] = useState('')
	const [sortBy, setSortBy] = useState<SortByField>('updatedAt')
	const [data, setData] = useState<DataItem[]>([])
	const [channelNames, setChannelNames] = useState<string[]>([])
	const [statuses, setStatuses] = useState<string[]>([])
	const [isRewindLoading, setIsRewindLoading] = useState(false)
	const [isNextStageLoading, setIsNextStageLoading] = useState(false)
	const [isPrevStageLoading, setIsPrevStageLoading] = useState(false)
	const [isUpdateLoading, setIsUpdateLoading] = useState(false)
	const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)
	const [isProcessing, setIsProcessing] = useState(false)
	const coreFieldsRef = useRef<HTMLDivElement>(null)
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const canTakeFeedback =
		selectedVideoIdea && selectedVideoIdea.stage != 'READY_TO_REVIEW' && selectedVideoIdea.stage != 'APPROVED'
	// parse and stringify content to have same whitespaces and escape characters
	const canUpdate = selectedVideoIdea && data && JSON.stringify(selectedVideoIdea.data) != JSON.stringify(data)
	const location = useLocation()
	const newVideoIdeaId = location.state?.newVideoIdeaId
	const filterChannelDisplayName = location.state?.filterChannelDisplayName
	const [filterChannelName, setFilterChannelName] = useState(filterChannelDisplayName)
	const navigate = useNavigate()

	const fetchVideoIdeas = useCallback(
		async (refresh = false) => {
			try {
				if (refresh) {
					setIsRefreshing(true)
				} else {
					setIsLoading(true)
				}
				const response = await listAllVideoIdeas()
				if (JSON.stringify(videoIdeas) !== JSON.stringify(response.data)) {
					console.log('Video Ideas updated')
					setVideoIdeas(response.data)
					const newChannelNames = Array.from(
						new Set(response.data.map((vi: VideoIdea) => vi.channelDisplayName))
					)
						.sort()
						.filter((name): name is string => typeof name === 'string')
					setChannelNames(newChannelNames)
					if (!newChannelNames.includes(filterChannelName)) {
						setFilterChannelName('')
					}
					setStatuses(Array.from(new Set(response.data.map((vi: VideoIdea) => vi.stage))))
				}
			} catch (error) {
				console.error(`Error in fetching video ideas: ${error}`)
				toast.error('Error fetching video ideas')
			} finally {
				setIsLoading(false)
				setIsRefreshing(false)
			}
		},
		[filterChannelName]
	)

	const applyFiltersAndSort = () => {
		let filtered = [...videoIdeas]
		if (filterChannelName) {
			filtered = filtered.filter((vi: VideoIdea) => vi.channelDisplayName === filterChannelName)
		}
		if (filterStatus) {
			filtered = filtered.filter((vi: VideoIdea) => vi.stage === filterStatus)
		}
		filtered.sort((a, b) => new Date(b[sortBy]).getTime() - new Date(a[sortBy]).getTime())
		setFilteredVideoIdeas(filtered)
	}

	useEffect(() => {
		fetchVideoIdeas()

		const intervalId = setInterval(() => {
			fetchVideoIdeas(true)
		}, 10000)

		return () => clearInterval(intervalId)
	}, [])

	useEffect(() => {
		if (selectedVideoIdea) {
			console.log('Updating selected video idea')
			const updatedVideoIdea = videoIdeas.find((vi: VideoIdea) => vi.id === selectedVideoIdea.id)
			if (updatedVideoIdea && JSON.stringify(selectedVideoIdea) !== JSON.stringify(updatedVideoIdea)) {
				setSelectedVideoIdea({ ...updatedVideoIdea })
				setData([...updatedVideoIdea.data])
			}
		}
	}, [selectedVideoIdea, videoIdeas])

	useEffect(() => {
		if (newVideoIdeaId) {
			const newVideoIdea = videoIdeas.find((vi: VideoIdea) => vi.id === newVideoIdeaId)
			if (newVideoIdea) {
				setSelectedVideoIdea(newVideoIdea)
				setData([...newVideoIdea.data])
				setShowModal(true)
			}
		}
	}, [newVideoIdeaId, videoIdeas])

	useEffect(() => {
		applyFiltersAndSort()
	}, [videoIdeas, filterChannelName, filterStatus, sortBy])

	useEffect(() => {
		if (selectedVideoIdea) {
			setIsProcessing(selectedVideoIdea.isProcessing)
		}
	}, [selectedVideoIdea])

	const handleVideoIdeaClick = (videoIdea: VideoIdea) => {
		setSelectedVideoIdea(videoIdea)
		setData([...videoIdea.data])
		setShowModal(true)
	}

	const adjustTextareaHeight = () => {
		const textarea = textareaRef.current
		if (textarea && textarea.style.height != `${textarea.scrollHeight}px`) {
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}

	const handleContentChange = (newDataItem: DataItem) => {
		setData((prev: DataItem[]) => {
			if (prev === undefined) return prev
			prev[prev.length - 1] = newDataItem
			return [...prev]
		})
		adjustTextareaHeight()
	}

	const handleUpdateVideoIdea = useCallback(async () => {
		try {
      if (!selectedVideoIdea) return
			setIsUpdateLoading(true)
			await updateVideoIdea(selectedVideoIdea.channelId, selectedVideoIdea.id, { data })
			await fetchVideoIdeas(true)
			toast.success('Video idea updated successfully')
		} catch (error) {
			toast.error('Error updating video idea')
		} finally {
			setIsUpdateLoading(false)
		}
	}, [data])

	const handleCloseModal = () => {
		setShowModal(false)
		setSelectedVideoIdea(null)
		setFeedback('')
		setData([])
	}

	const confirmIfUpdates = useCallback(() => {
		if (canUpdate) {
			return window.confirm('You have unsaved changes. Do you want to proceed without saving?')
		}
		return true
	}, [canUpdate])

	const waitForProcessing = async () => {
		if (selectedVideoIdea?.isProcessing) {
			await fetchVideoIdeas(true)
			const updatedVideoIdea = videoIdeas.find((vi: VideoIdea) => vi.id === selectedVideoIdea.id)
			if (updatedVideoIdea && updatedVideoIdea.isProcessing) {
				await new Promise((resolve) => setTimeout(resolve, 1000))
				await waitForProcessing()
			}
		}
	}

	const handleSubmitFeedback = async () => {
		if (confirmIfUpdates()) {
			try {
        if (!selectedVideoIdea) return
				setIsFeedbackLoading(true)
				setIsProcessing(true)
				await submitFeedback(selectedVideoIdea.channelId, selectedVideoIdea.id, feedback)
				setFeedback('')
				toast.success('Request to apply feedback submitted successfully')
				waitForProcessing()
			} catch (error) {
				toast.error('Error applying feedback')
			} finally {
				setIsFeedbackLoading(false)
			}
		}
	}

	const handleGoToNextStage = async () => {
		if (confirmIfUpdates()) {
			try {
        if (!selectedVideoIdea) return
				setIsNextStageLoading(true)
				setIsProcessing(true)
				await goToNextStage(selectedVideoIdea.channelId, selectedVideoIdea.id)
				toast.success('Request to move to next stage submitted successfully')
				waitForProcessing()
			} catch (error) {
				toast.error('Error moving to next stage')
			} finally {
				setIsNextStageLoading(false)
			}
		}
	}

	const handleGoToPrevStage = async () => {
		if (confirmIfUpdates()) {
			try {
        if (!selectedVideoIdea) return
				setIsPrevStageLoading(true)
				await goToPrevStage(selectedVideoIdea.channelId, selectedVideoIdea.id)
				await fetchVideoIdeas(true)
				toast.success('Moved to prev stage successfully')
			} catch (error) {
				toast.error('Error moving to prev stage')
			} finally {
				setIsPrevStageLoading(false)
			}
		}
	}

	const handleDeleteVideoIdea = async () => {
		try {
      if (!selectedVideoIdea) return
			await deleteVideoIdea(selectedVideoIdea.channelId, selectedVideoIdea.id)
			toast.success('Video idea deleted successfully')
			fetchVideoIdeas(true)
			handleCloseModal()
		} catch (error) {
			toast.error('Error deleting video idea')
		}
	}

	useEffect(() => {
		// wait for the element to load properly
		setTimeout(() => {
			if (coreFieldsRef.current) {
				coreFieldsRef.current.scrollTop = coreFieldsRef.current.scrollHeight
			}
		}, 100)
		adjustTextareaHeight()
	}, [data])

	const handleRewindVideoIdea = async () => {
		if (confirmIfUpdates()) {
			try {
        if (!selectedVideoIdea) return
				setIsRewindLoading(true)
				await rewind(selectedVideoIdea.channelId, selectedVideoIdea.id)
				await fetchVideoIdeas(true)
				toast.success('Successfully reverted stage')
			} catch (err) {
				toast.error('Error in rewinding')
			} finally {
				setIsRewindLoading(false)
			}
		}
	}

	const getThumbnail = useCallback((videoIdea: VideoIdea) => {
		const filteredDataItems = videoIdea.data?.filter((di: DataItem) => di.type == 'image')
		if (filteredDataItems?.length) {
			return filteredDataItems[0].content
		}
		return logo
	}, [])

	return (
		<Container fluid>
			<h1 className='my-4'>Video Builder</h1>

			{/* Control Bar */}
			<Row className='mb-4'>
				<Col md={4}>
					<Form.Control
						as='select'
						value={filterChannelName}
						onChange={(e) => setFilterChannelName(e.target.value)}>
						<option value=''>Filter by Channel Name</option>
						{channelNames.map((channelName) => (
							<option key={channelName} value={channelName}>
								{channelName}
							</option>
						))}
					</Form.Control>
				</Col>
				<Col md={4}>
					<Form.Control as='select' value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
						<option value=''>Filter by Stage</option>
						{statuses.map((status) => (
							<option key={status} value={status}>
								{status}
							</option>
						))}
					</Form.Control>
				</Col>
				<Col md={4}>
					<Form.Control as='select' value={sortBy} onChange={(e) => setSortBy(e.target.value as SortByField)}>
						<option value='updatedAt'>Sort by Last Updated</option>
						<option value='createdAt'>Sort by Created Date</option>
					</Form.Control>
				</Col>
			</Row>

			{/* Video Ideas Grid */}
			<Row>
				{isLoading ? (
					<Loader />
				) : filteredVideoIdeas.length === 0 ? (
					<Col className='text-center'>
						<h3>No video ideas yet!</h3>
						<p>Start brainstorming new ideas from the channels page.</p>
						<Button variant='primary' onClick={() => navigate('/home')}>
							Go to Channels
						</Button>
					</Col>
				) : (
					filteredVideoIdeas.map((videoIdea) => (
						<Col key={videoIdea.id} md={2} className='mb-4'>
							<Card
								onClick={() => handleVideoIdeaClick(videoIdea)}
								style={{ cursor: 'pointer' }}
								className='work-unit-card'>
								<div className='work-unit-thumbnail'>
									<img src={getThumbnail(videoIdea)} alt='Thumbnail' />
								</div>
								<Card.Body className='card-body'>
									<Card.Text>
										<strong>Channel Name:</strong> {videoIdea.channelDisplayName}
										<br />
										<strong>Stage:</strong> {videoIdea.stageDisplayName}
										<br />
										<strong>Type:</strong>{' '}
										{videoIdea.type ? (videoIdea.type === 'long' ? 'Long' : 'Short') : 'Long'} Form
										<br />
										<i>
											{videoIdea.isProcessing
												? 'Processing...'
												: `Updated ${formatRelative(videoIdea.updatedAt)}`}
										</i>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))
				)}
			</Row>

			{/* Video Idea Modal */}
			<Modal show={showModal} onHide={handleCloseModal} size='lg'>
				<Modal.Header>
					<div className='d-flex flex-column align-items-start'>
						<Modal.Title>{selectedVideoIdea?.channelDisplayName} - Video Idea</Modal.Title>
						{selectedVideoIdea && (
							<small>
								<i>
									<strong>
										{isProcessing
											? 'Processing...'
											: `Updated ${formatRelative(selectedVideoIdea?.updatedAt)}`}
									</strong>
								</i>
							</small>
						)}
					</div>
					<FaTrash
						onClick={handleDeleteVideoIdea}
						style={{
							cursor: 'pointer',
							marginLeft: 'auto',
							fontSize: '1.5rem',
							color: '#dc3545',
							marginRight: '15px',
						}}
					/>
				</Modal.Header>
				<Modal.Body style={{ padding: '25px' }}>
					{selectedVideoIdea && (
						<StagePipeline
							stages={selectedVideoIdea.allStages || []}
							currentStage={selectedVideoIdea.stageDisplayName}
						/>
					)}
					<hr />
					<div style={{ maxHeight: '45vh', overflowY: 'auto' }} ref={coreFieldsRef}>
						{data &&
							data.map((di, index) => {
								const isLastField = index === data.length - 1
								const isEditable = isLastField && di.type === 'text'

								return (
									<div key={di.name} className='mb-3'>
										<h5 className='text-primary'>{di.name.replace(/_/g, ' ')}</h5>
										{di.thought && (
											<Accordion className='mt-2 mb-3'>
												<Accordion.Item eventKey='0'>
													<Accordion.Header>
														<i className='fas fa-lightbulb text-warning me-2'></i>
														Chain of Thought
													</Accordion.Header>
													<Accordion.Body>
														<Markdown className='em'>{di.thought}</Markdown>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										)}
										{di.type === 'text' ? (
											isEditable ? (
												<div className='d-flex flex-column'>
													<Form.Control
														as='textarea'
														ref={textareaRef}
														value={di.content}
														onChange={(e) =>
															handleContentChange({ ...di, content: e.target.value })
														}
														className='core-data-input'
													/>
													{(selectedVideoIdea?.allowRewind || canUpdate) && (
														<div className='d-flex justify-content-between mt-2'>
															<Button
																variant='info'
																onClick={handleRewindVideoIdea}
																disabled={isRewindLoading || isProcessing}
																className='mt-2'
																style={{
																	visibility: selectedVideoIdea?.allowRewind
																		? 'visible'
																		: 'hidden',
																}}>
																{isRewindLoading && (
																	<Spinner
																		as='span'
																		animation='border'
																		size='sm'
																		role='status'
																		aria-hidden='true'
																		className='mx-2'
																	/>
																)}
																&lt; Rewind
															</Button>
															<Button
																variant='primary'
																onClick={handleUpdateVideoIdea}
																disabled={isUpdateLoading || isProcessing}
																className='mt-2'
																style={{
																	visibility: canUpdate ? 'visible' : 'hidden',
																}}>
																{isUpdateLoading && (
																	<Spinner
																		as='span'
																		animation='border'
																		size='sm'
																		role='status'
																		aria-hidden='true'
																		className='mx-2'
																	/>
																)}
																Save
															</Button>
														</div>
													)}
												</div>
											) : (
												<Markdown className='text-muted'>{di.content}</Markdown>
											)
										) : di.type === 'image' ? (
											<div>
												<img
													src={di.content}
													alt={di.name}
													style={{ maxWidth: '100%', height: 'auto' }}
												/>
											</div>
										) : (
											<p>Unsupported field type: {di.type}</p>
										)}
									</div>
								)
							})}
					</div>
					<hr />
					{canTakeFeedback && (
						<div className='mt-4'>
							<Form.Group>
								<Form.Label>
									<strong>Feedback on Current Stage</strong>
								</Form.Label>
								<Form.Control
									as='textarea'
									disabled={isFeedbackLoading || isProcessing}
									rows={4}
									value={feedback}
									onChange={(e) => setFeedback(e.target.value)}
									className='feedback-input'
								/>
								<div className='d-flex justify-content-end mt-2'>
									<Button
										variant='success'
										onClick={handleSubmitFeedback}
										disabled={!feedback || isFeedbackLoading || isProcessing}>
										{isFeedbackLoading && (
											<Spinner
												as='span'
												animation='border'
												size='sm'
												role='status'
												aria-hidden='true'
												className='mx-2'
											/>
										)}
										Regenerate with feedback
									</Button>
								</div>
							</Form.Group>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-between'>
					<Button
						variant='info'
						onClick={handleGoToPrevStage}
						disabled={isPrevStageLoading || isProcessing}
						style={{ visibility: selectedVideoIdea?.allowPrevStage ? 'visible' : 'hidden' }}>
						{isPrevStageLoading && (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
								className='mx-2'
							/>
						)}
						&lt;&lt; Prev: {selectedVideoIdea?.prevStageDisplayName}
					</Button>
					<Button
						variant='primary'
						onClick={handleGoToNextStage}
						disabled={isNextStageLoading || isProcessing}
						style={{ visibility: selectedVideoIdea?.allowNextStage ? 'visible' : 'hidden' }}>
						{isNextStageLoading && (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
								className='mx-2'
							/>
						)}
						Next: {selectedVideoIdea?.nextStageDisplayName} &gt;&gt;
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	)
}

export default VideoBuilder
